import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import React from "react"

import * as s from "../styles/Card.module.scss"
import { CardNode } from "../utils/types"

const Card = ({ node }: { node: CardNode }) => {
  const loadedImage = getImage(node.frontmatter.preview_image)

  return (
    <>
      <Link to={`/blog/${node.slug}`} className={s.card}>
        <div className={s.layout}>
          <GatsbyImage className={s.image} image={loadedImage} alt="TODO" />
          <div className={s.text}>
            <div>
              <span className={s.title}>{node.frontmatter.title}</span>
              <span>{node.frontmatter.date}</span>
            </div>
            <span>{node.frontmatter.description}</span>
          </div>
        </div>
      </Link>
      <Link to={`/blog/${node.slug}`} className={s.cardsmall}>
        <div className={s.layout}>
          <div>
            <GatsbyImage className={s.image} image={loadedImage} alt="TODO" />
            <div className={s.text}>
              <span className={s.title}>{node.frontmatter.title}</span>
              <span>{node.frontmatter.date}</span>
            </div>
          </div>
          <span>{node.frontmatter.description}</span>
        </div>
      </Link>
    </>
  )
}

export default Card
