import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"

import Card from "../../components/Card"
import Layout from "../../components/Layout"

import * as s from "../../styles/index.module.scss"
import { loadTheme } from "../../utils/themes"
import { ThemeContext } from "../../components/ThemeProvider"

export default function Blog({ data }) {
  return (
    <>
      <Layout>
        <div className={s.container}>
          <h1 className={s.title}>New Posts</h1>

          {data.allMdx.nodes.length != 0 ? (
            data.allMdx.nodes.map(node => <Card node={node} />)
          ) : (
            <p className={s.whoopsiefloopsie}>
              <i>Whoopsie floopsie, there appears to be nothing here yet...</i>
            </p>
          )}
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          description
          preview_image {
            childImageSharp {
              gatsbyImageData(width: 128, height: 128)
            }
          }
          preview_image_alt
        }
        id
        slug
      }
    }
  }
`
